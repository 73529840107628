.menu-wrapper{
    // background-color: #ecdbe3;
    background-color: #ececec;
    height: 44px;
    padding-top: 11px;
    position: fixed;
    width: 100%;
    top: 0;
    left:0;
    z-index: 1;

    a{
        text-decoration: none;
        color:inherit;
    }
    .instagram-icon{
        padding-top: 2px;
    }

    span{
        cursor: pointer;
    }

    span:hover, a:hover{
        text-decoration: underline;
    }

    .underlined{
        text-decoration: underline;
    }

    .menu-left{
        padding-left:44px;

        a, span{
            padding-right:22px;
            float:left;
        }

    }

    .menu-right-mobile-icon{
        float: right;
        margin-right: 24px;
        display:none;
        cursor: pointer;
        padding-top: 3px;
    }
    .menu-right-mobile{
        float: right;
        display: grid;
        grid-template-columns: auto auto;
        width: 100%;
        justify-content: start;
        background: #EFEDEB;
        position: absolute;
        top: 39px;
        padding: 10px 44px 10px 24px;
        display:none;
        .filter-option-wrapper{
            padding-right: 22px;
        }
    }
    .menu-right-mobile-display{
        display:none;
    }

    .menu-right{
        float: right;
        display: grid;
        grid-template-columns: auto auto;
        padding-right: 44px;
        .filter-option-wrapper{
            padding-left: 22px;
        }
    }
    .filter-option-wrapper{
        align-items:center;
        display: grid;
        grid-template-columns: 20px auto;
        opacity: 0.5;
        cursor: pointer;
        column-gap: 6px;

        .filter-checkbox{
            height: 14px;
            width: 14px;
            border: thin solid;
            display: inline-block;
            border-radius: 50%;
        }
        .checked{
            background-color: black;
        }
    }

    .filter-option-wrapper:hover{
        .unchecked{background-color: black;}
    }
}

@media screen and (max-width:850px){
    // span:hover, a:hover{
    //     // text-decoration: none !important;
    // }
    .menu-title{
        text-decoration: none !important;
    }
    .menu-right-mobile-icon{
        display:block !important;
    }
    .menu-right-mobile-display{
        display:grid !important;
    }
    .menu-right{
       display:none !important;
    }
    .menu-left{
        padding-left:24px !important;
    }
}