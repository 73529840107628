.content-wrapper{
    padding: 44px;
    margin-top:44px;
    // touch-action: pan-y;
    max-height: 999999px;

    .masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        margin-left: -44px; /* gutter size offset */
        width: auto;
      }
      .masonry-grid_column {
        padding-left: 44px; /* gutter size */
        background-clip: padding-box;
      }
      
      /* Style your items */
      .masonry-grid_column > a { /* change div to reference your elements you put in <Masonry> */
        margin-bottom: 44px;
        display: block;
      }

      .project-box{
        // touch-action: pan-y;
        position: relative;
        

        a{
            text-decoration: none;
            color:inherit
        }

        .project-box-title{
            width:100%;
            position: absolute;
            background-color: #EFEDEB;
            padding: 15px;
            display: none;
        }

        img{
            width: 100%;
            // touch-action: pan-y;
        }
    }
    .project-box:hover{
        .project-box-title{
            display: block;
        }
    }

}

// @media screen and (max-width:850px) {
//   .content-wrapper{
//     padding: 24px;
//   }
// }

@media screen and (max-width:850px) {
    .content-wrapper{
        padding: 24px;

        .project-box{
          // margin-bottom: 24px;
        }

        .masonry-grid {
            margin-left: -24px; /* gutter size offset */
          }
          .masonry-grid_column {
            padding-left: 24px; /* gutter size */
            padding-bottom: 18px;
          }
          
          /* Style your items */
          .masonry-grid_column > a { /* change div to reference your elements you put in <Masonry> */
            margin-bottom: 24px;
            display: block;
          }
    }
}