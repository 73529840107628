
.project{
    max-height: 999999px;
    width:100%;
    float:left;

    .project-scroll-button{
        float:left;
        width:100%;
        padding: 22px;

        div{
            cursor: pointer;
            display: inline;
        }
    }

    .layout-switch-wrapper{
        position: fixed;
        top: 17px;
        left: 17px;
        z-index: 99;

        .layout-switch-button{
            background-color: green;
            height:10px;
            width:10px;
            border-radius: 50%;
            opacity: 1;
            cursor: pointer;
        }
        .layout-switch-button:hover{
            opacity: 0.5;
        }
    }
    .bottom-wrapper{
        position: fixed;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding:11px 44px;
        z-index: 999;

        .bottom-nav-prev{
            cursor: pointer;
            justify-self: start;
        }
        // .bottom-nav-prev:hover{
        //     text-decoration: underline;
        // }

        .bottom-nav-middle{
            display: block;
            // cursor: pointer;
            justify-self: center;
            z-index: 999;
            pointer-events: all;

            div{
                color: #ececec;
                transition: color 0.2s ease-in-out;
                z-index: 999;
                cursor: pointer;
            }

            div:hover{
                text-decoration: underline;
            }

            .button-fade-in{
                color: black;
            }
        }
        // .bottom-nav-middle:hover{
        //     text-decoration: underline;
        // }
        .bottom-nav-next{
            cursor: pointer;
            justify-self: end;
        }
        // .bottom-nav-next:hover{
        //     text-decoration: underline;
        // }

    }

        .media-wrapper{
            padding:22px 22px;
            margin-bottom: 66px;
            margin-top: 44px;
            float: left;
            width:100%;
            max-height: 999999px;

            .first-row{
                float:left;
                width:100%;
            }

            .content-text-full-width{
                width:100%;
                float:left;
            }

            .content-text{
                width: 33.3333%;
                float: right;
                padding:22px 44px 22px 22px;
            }

            .content-text.align-left{
                float: left;
                padding-left:22px;
            }

            .width-16{
                width:16.5%;
            }
    
            .width-25{
                width:25%;
            }
            .width-33{
                width: 33.3333%;
            }
            .width-50{
                width:50%;
                // width:calc(50% + 22px);
            }
            .width-66{
                width:66.6666%;
            }
            .width-82{
                width:calc(82.5% + 22px);
            }
            .width-100{
                width:100%;
            }

            .text-pos-wrapper{
                width:100%;
                float:left;

                .pos-left{
                    float: left;
                }

                .pos-middle{
                    float: none !important;
                    margin-left:auto;
                    margin-right:auto;
                }

                .pos-right{
                    float: right !important;
                    text-align:right;
                }
            }

            .beside-true{
                width:auto;
            }
            .beside-false{
                // width:100%;
                clear:both;
            }

            .div-clear{
                float:none !important;
            }

            .image-wrapper{
                float:left;
                padding:22px;
                display: grid;

                span{
                    display: block !important;
                }
            }
            .full{
                // float:none;
                // margin:22px;
            }

            .caption-align-left{
                text-align: left;
            }
            .caption-align-middle{
                text-align: center;
            }
            .caption-align-right{
                text-align: right;
            }
            
            .image-caption{
                padding:11px 0px 22px 0px;
                // padding: 22px 0px 44px 0px;
                // height: 0;
                height: auto !important;
            }
            img{
                max-width: 100%;
            }

            .align-left{
                text-align: left;
            }
            .align-center{
                text-align: center;
            }
            .align-right{
                text-align: right;
            }
            .align-Left{
                text-align: left;
            }
            .align-Center{
                text-align: center;
            }
            .align-Right{
                text-align: right;
            }

            .text-wrapper{
                float: left;
                padding:22px;
                // width:100%;

                // p{
                //     width: 50%;
                // }
            }
            .empty-wrapper{
                float: left;
                padding:22px;
                height:100%;
            }
            .line-break{
                width:100%;
                float:left;
            }
            .video-wrapper{
                float: left;
                
                padding:22px 22px;
                // display: grid;
                // justify-content: center;
                div{
                    // width:calc(75vw - 77px) !important;
                    // height: calc((75vw - 77px) / 1.5625 ) !important;
                    // width: calc(75vw - 77px) !important;
                    // height: calc((75vw - 77px) / 1.747) !important;
                }
                iframe{
                    width:100% !important;
                }
            }
            .audio-file-wrapper{
                float: left;
                padding:22px 22px;

                .audio-file{
                    width: 100%;
                }
            }
        }
}

@media screen and (max-width:1300px){



}

@media screen and (max-width:1000px){
    .width-16{
        width:33% !important;
    }
    .width-25{
        width:50% !important;
    }
    .width-33{
        width:50% !important;
    }
    // .content-text.width-33{
    //     width:100% !important;
    // }
    .width-50{
        width:50% !important;
    }
    .width-82{
        width:75% !important;
    }
    .content-text{
        float: left !important;
    }

}

@media screen and (max-width:850px){
    body{
        font-size: 16px;
        // height: 100%;
        // -webkit-overflow-scrolling: touch;
    }

    .bottom-wrapper{
        // grid-template-columns: 1fr 1fr !important;
        padding:11px 22px !important;
    }

    .content-text{
        width:100% !important;
        padding: 22px 11px !important;
    }

    .media-wrapper{
        padding:11px !important;
        margin-top: 44px;

        .image-caption{
            padding:5px 0px 0px 0px !important;
            // padding:11px 0px 0px 0px !important;
            // padding: 22px 0px 22px 0px !important;
            height: auto !important;
        }

        .width-16{
            width:50% !important;
        }
        .width-25{
            width:50% !important;
        }
        .width-33{
            width:100% !important;
        }
        .width-50{
            width:100% !important;
        }
        .width-66{
            width:100% !important;
        }
        .width-82{
            width:100% !important;
        }
    
        .text-wrapper{
            padding: 22px 11px !important;
        }

        .image-wrapper{
            padding: 11px 11px !important;
        }

        .video-wrapper{
            padding:11px !important;
        }
        .audio-file-wrapper{
            padding:11px !important;
        }

        .empty-wrapper{
            display:none;
            // padding:11px !important;
        }
    }

    // .project .content-wrapper .media-wrapper{
    //     .video-wrapper{
    //         padding: 66px 0px;
    //         div{
    //             width:calc(100vw - 44px) !important;
    //             height: calc((100vw - 44px) / 1.5625 ) !important;
    //         }
            
    //     }
    // }

}