@font-face {
  font-family: 'HS-regular';
  src: url('./fonts/HS-regular.woff2') format('woff2'),
      url('./fonts/HS-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HS-regular-italic';
  src: url('./fonts/HS-regular-italic.woff2') format('woff2'),
      url('./fonts/HS-regular-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'HS-bold-regular';
  src: url('./fonts/HS-bold-regular.woff2') format('woff2'),
      url('./fonts/HS-bold-regular.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HS-bold-italic';
  src: url('./fonts/HS-bold-italic.woff2') format('woff2'),
      url('./fonts/HS-bold-italic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

html{
  font-size: 100%;
  // touch-action: pan-y;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
}

body{
  font-family: 'HS-regular', sans-serif;
  // font-family: 'DM Sans';
  font-weight: 400;
  font-size: 16px;
  // font-stretch: normal;
  font-style: normal;
  // text-rendering: optimizeLegibility;
  -webkit-font-smoothing:antialiased;
  line-height: 23px;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;

  .no-touch{
    position: fixed;
    z-index: -999;
    height: 100vh;
    width: 100vw;
    touch-action: none;
    touch-action: pan-y;
  }

  .hidden-emojis{
    opacity: 0;
    position: fixed;
    z-index: -999;
    pointer-events: none;
  }
  p:empty{
    padding:16px 0px;
  }

  .screensaver-container{
    left:0;
    position: fixed;
    z-index: 999;
    width:100%;
    height:calc(100vh - 88px);
    top:44px;
    font-size: 8vw;
    line-height: 7.5vw;
    will-change: transform;
    transform: translateZ(0);

    .hooks-container {
      width: 100%;
      height: 100%;
      position: relative;
      will-change: transform;
      transform: translateZ(0);
    }
    
    .hooks-element {
      width: 8vw;
      // width:100px;
      height: 8vw;
      will-change: transform;
      transform: translateZ(0);
    }
    
    .hooks-element img {
      object-fit: fill;
      width:100%;
      height:100%;
      max-width: 100%;
      pointer-events: none;
      will-change: transform;
      transform: translateZ(0);
      position: absolute;
      opacity:0;
      // display: none;
    }
    .show-emoji{
      opacity:1 !important;
    }
  }

  .bottom-wrapper{
    // background-color: #e1ecdb;
    background-color: #ececec;
    height: 44px;
    padding-top: 12px;
    // position: fixed;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    // top: 0;
    width: 100%;
    bottom: 0;
    z-index: 1;

    a{
      text-decoration: none;
    }
    a:hover{
      text-decoration: underline;
    }

    .bottom-project-count{
        text-align: center;
    }
  }
}

a{
  color: inherit;
  line-break: anywhere;
}

em{
  // font-family: 'HS-regular-italic';
  font-style: italic;
}

img{
  display: block;
}

strong, b{
  font-family: 'HS-bold-regular';
  font-weight: 600;
}

@media (hover: none) {

    .screensaver-container{
      height:calc(100% - 88px) !important;
      font-size: 100px !important;
      line-height: 100px !important;

      .hooks-element {
        width: 100px !important;
        height:100px !important;
        position: relative;
      }
    }

}

@media screen and (max-width:767px){

  body{
    font-size: 18px !important;
  }

  .screensaver-container{
    height:calc(100% - 88px) !important;
    font-size: 100px !important;
    line-height: 100px !important;

    .hooks-element {
      width: 100px !important;
      height:100px !important;
      position: relative;

      // img{
      //   position: absolute;
      //   top:50%;
      //   transform:translatey(-50%);
      // }
      // height: 100px !important;
    }
  }

}