@font-face{
    font-family:'digital-7_monoitalic';
    src: url('../../fonts/digital-7_mono_italic-webfont.woff2') format('woff2'),
    url('../../fonts/digital-7_mono_italic-webfont.woff2') format('woff');
    font-weight:400;
    font-style:normal
}
.clock-wrapper *{
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box
}
.group:after{
    content:"";
    display:table;
    clear:both
}
.clock-wrapper{
    // width:100%;
     position: relative;
    //  height: 260px;
     line-height:1;
     float:left;
     margin: 24px;
}

#clock{
    background:none repeat scroll 0 0 #000;
    // height:260px;
    // padding:29px 0 29px 0px;
    text-align:center;
    width:100%;
    // width:580px
}
#clock span:not(.blink){
    font-family:'digital-7_monoitalic';
    // font-size:207px;
    font-size: 18vw;
    letter-spacing:4pt
}
#clock span{
    color:#fff;
    text-align:center
}
#clock .blink{
    display:inline-block;
    font-family:"times new roman";
    // font-size:162px;
    font-size: 16vw;
    font-weight:400;
    height:auto;
    margin-bottom:11px;
    margin-left:-7px;
    margin-right:-2px;
    text-align:center;
    vertical-align:text-bottom;
    width:8%
}

@media screen and (max-width:950px) {
    // #clock, .clock-wrapper{
    //     height: 220px !important;
    // }
    // #clock span:not(.blink){
    //     font-size: 160px !important;
    // }
    // #clock .blink{
    //     font-size: 122px !important;
    // }

}

@media screen and (max-width:850px) {
    // .clock-wrapper{
    //     height: 185px !important;
    // }
    #clock{
        width: calc(100% - 24px) !important;
        // height: 185px !important;
    }
    .clock-wrapper{
        margin: 11px !important;
    }
    #clock span:not(.blink){
        font-size: 36vw;
        // font-size: 129px !important;
    }
    #clock .blink{
        font-size: 29vw;
        // font-size: 100px !important;
    }

}