.cursor{
    max-width:300px;
    position: fixed;
    transform: translate(18px,25px);
    z-index: 999;
    pointer-events: none;
    background-color: #ececec;
    // background-color: #dbdfec;
    // background-color: #EFEDEB;
    padding:15px;


    box-shadow:
    0px 0px 1.3px rgba(0, 0, 0, 0.019),
    0px 0px 3.3px rgba(0, 0, 0, 0.027),
    0px 0px 6.7px rgba(0, 0, 0, 0.033),
    0px 0px 13.9px rgba(0, 0, 0, 0.041),
    0px 0px 38px rgba(0, 0, 0, 0.06)
    ;
}

.left-align{
    transform: translate(calc(-100% - 20px),20px);
}