.info{

    .width-16{
        width:16.5%;
    }

    .width-25{
        width:25%;
    }
    .width-33{
        width: 33.3333%;
    }
    .width-50{
        width:50%;
        // width:calc(50% + 22px);
    }
    .width-66{
        width:66.6666%;
    }
    .width-82{
        width:calc(82.5% + 22px);
    }
    .width-100{
        width:100%;
    }
    
    .content-wrapper{
        // width:50%;
        max-width: 100%;
        max-height: 999999px;

        p{
            width:50%;
        }

        p:empty {
            padding-bottom:22px;
        }

        img{
            max-width: 100%;
        }
    }

    .list-wrapper{
        margin:40px 0px;

        .list-title{
            font-weight: 600;
            padding-bottom: 10px;
        }

        .divider{
            padding-top: 10px;
        }
        .list-item-image{
            margin-top: 22px;
        }

        .list-item{
            display: grid;
            grid-template-columns: 60px auto;
            line-height: 25px;
            

            .list-item-year{
                // display: inline;
                // width: 50px;
                // float: left;
            }
            .list-item-title{
                // display: inline;
            }
        }
    }

    .mondrian-wrapper{

        img{
            // max-width: 100px;
        }
    }

    .mondrian-image{
        margin:22px 0px 66px -11px;
    }

    .bottom-wrapper{
        padding: 12px 44px 0px 44px;
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        justify-content: space-between;

        .bottom-section-link{
            cursor:pointer;
        }
        .bottom-section-link:hover{
            text-decoration: underline;
            // font-weight:bold;
        }
        .section-active{
            text-decoration: underline;
        }
    }
    .bottom-nav-toggle-button{
        position: absolute;
        top: 12px;
        right: 14px;
        font-size: 23px;
    }
    .bottom-nav-mobile-closed{
        position: fixed;
        text-align:center;
        width:100%;
        height:44px;
        bottom:0px;
        padding:12px;
        background-color: #ececec;
        .bottom-nav-label{
            // padding-top: 10px;
        }
    }

    .bottom-nav-mobile-open{
        position: fixed;
        text-align:center;
        height: auto;
        padding:12px;
        width:100%;
        bottom:0px;
        background-color: #ececec;
    }
}

@media screen and (max-width:1000px){
    .content-wrapper{
        width:100% !important;
    }
}

@media screen and (max-width:850px){

    body{
        font-size: 16px;
    }

    .mondrian-wrapper{
        margin-bottom: 44px;
    }

    .width-16{
        width:50% !important;
    }
    .width-25{
        width:50% !important;
    }
    .width-33{
        width:100% !important;
    }
    .width-50{
        width:100% !important;
    }
    .width-66{
        width:100% !important;
    }
    .width-82{
        width:100% !important;
    }

    p{
        width:100% !important;
    }

    .list-title{
        padding-bottom:0px !important;
    }

    .divider{
        padding-top: 20px !important;
    }
    .list-item{
        display: block !important;

        // grid-template-columns: 0px auto !important;

        .list-item-year-divider{
            padding-bottom: 20px !important;
            line-height: auto !important;
        }

        .list-item-year{
            width:0px;
        }
        .list-item-title{
            // display: inline;
        }
    }
}